import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import './pdf-display.styles.scss'
import { Sources } from 'forms/formTypes';

const PdfDisplay = ({ cpduData, source }) => {

	const [ cat ] = useState(Sources[source].cat)
	
	const navigate = useNavigate();

	return (
		<div className='pdf-creator'>
			{
				(cpduData)
				?
				<div>
					<div className="uk-margin center">
						<a href={`https://drive.google.com/uc?export=download&id=${cpduData.cpduId}`}>
							<button className='g-button'>
								Download PDF
							</button>
						</a>
						<button className='g-button' onClick={() => navigate(`/home/${cat}`)}>View My Completed {cat.toUpperCase()} Evaluations</button>
					</div>
					<div className='iframe-container'>
						<iframe className='pdf-view' src={`https://drive.google.com/file/d/${cpduData.cpduId}/preview`}  allow="autoplay"></iframe>
					</div>
					
				</div>
				: 
				<div>
					<div className="uk-margin center">
						<button className='g-button' onClick={() => navigate(`/home/${cat}`)}>
							View My Completed {cat.toUpperCase()} Evaluations
						</button>
						<h3>Your evaluation has been recorded!</h3>
					</div>
				</div>
			}
		</div>
	)
}

export default PdfDisplay;