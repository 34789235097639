import React, { useState, useEffect, useRef } from 'react'
import './form.styles.scss'

import { Input, Textarea, Multi, Select } from 'components/inputs/inputs.component'

import { UseCloudFunction } from 'firebase-utils/firebase.utils'
import UIkit from 'uikit'
import flatpickr from 'flatpickr'

const Form = ({ evalData, formContent, setFormContent, userProfile, setCpduData }) => {

	const [ formData, setFormData ] = useState();
	const [ saving, setSaving ] = useState(false);
	const stateRef = useRef();
	stateRef.current = formData
	
	useEffect(() => {
		if (evalData && formContent) {
			// console.log(evalData)
			stateRef.current = {...stateRef.current, ...{
				'evalId': evalData.id,
				'evalItemId': evalData.itemId,
				'evalSource': evalData.source,
				'evalType': evalData.type
			}}
            setFormData(stateRef.current);
			// console.log(formContent)
			if (evalData.cpdu) {
				const date1Element = document.getElementById('date1')
				let fp1 = flatpickr(date1Element, 
				{
					altInput: true,
		            altFormat: "F j, Y",
		            dateFormat: "Y-m-d",
					onChange: (dates) => {saveDate(new Date(dates[0]).getTime())}
				});
			}
		}
	}, [evalData,formContent])

	function saveDate(value) {
		// console.log(stateRef.current)
        stateRef.current = {...stateRef.current, ...{'date': value}};
		setFormData(stateRef.current);
	}
	
	function handleTextChange(key, value) {
		// console.log(stateRef.current)
		stateRef.current = {...stateRef.current, ...{[key]: value}};
        setFormData(stateRef.current);
	}

	function handleMultipleChange(key, value) {
		// console.log(key, value)
		stateRef.current = {...stateRef.current, ...{[key]: (value) ? 1 : 0}};
        setFormData(stateRef.current);
	}

	function handleSelectChange(key, value) {
		// console.log(key, value)
		stateRef.current = {...stateRef.current, ...{[key]: Number(value)}};
        setFormData(stateRef.current);
	}

	async function saveEvaluation() {
		
		// console.log(formData)
		let requiredComplete = {};
		for (let f of formContent) {
			if (f.required == 'true') {
				requiredComplete[f.key] = false;
				if (f.type == 'multiple' || f.type == 'select') {
					for (let d of Object.keys(formData)) {
						if (d.includes(f.key) && formData[d] > 0) {
							requiredComplete[f.key] = true
						}
					}
				}
			}
		}
		if (evalData.cpdu && !formData.date) {
			requiredComplete.date = false
		}
		// console.log(requiredComplete)
		let formComplete = true;
		for (let c of Object.values(requiredComplete)) {
			if (!c) {
				formComplete = false
			}
		}
		if (!requiredComplete || !formComplete) {
			UIkit.modal.alert('Please complete the required parts of the evaluation before submitting.')
			return;
		}
		setSaving(true);
		// console.log(formData)
        let tempData = {...formData}
		tempData.ts = new Date().getTime();
		const res = await UseCloudFunction(
			'saveUserEval', 
			{
				'formData': tempData,
				'evalData': evalData,
				'userProfile': userProfile
			}
		)
		if (res.message = 'Eval saved') {
			setCpduData(res.cpduData)
			UIkit.modal.alert('Evaluation saved successfully!')
			.then(() => {
				window.scrollTo({top: 0, behavior: 'smooth'});
				setFormData(null)
				setFormContent(null)
				
			})
			
			
		} else {
			UIkit.modal.alert('Something went wrong, please try again in a few minutes.')
		}
		setSaving(false)
	}

	return (
		<div className='form-main'>
			
			{
				(formContent)
				?
				<div>
					{
						(evalData && evalData.cpdu)
						?
						<div>
							<p>This evaluation will not be associated with your personal information in any way. Your email will be used only so we can send you the evidence of completion document for your records. If you are an Illinois educator, you will need to import the information from this document into ELIS manually.</p>
							<div className='uk-margin'>
								<label className='section-label'>Date of Event</label><span className='uk-margin-left required'>* Required</span>
								<input className="uk-input flatpickr flatpickr-input" type="text" id="date1" placeholder="Pick a date..." />
							</div>

                            <div className='uk-margin'>
								<label className='section-label'>Your State</label><span className='uk-margin-left required'>* Required</span>
								<select className="uk-select" onChange={(e) => handleTextChange('state', e.target.value)} >
                                    <option value=''>Select your state...</option>
                                    <option value='Illinois'>Illinois</option>
                                    <option value='New York'>New York</option>
                                    <option value='Other'>Other</option>
                                </select>
							</div>
						</div>
						
						: <p>This evaluation will not be associated with your personal information in any way.</p>
					}
					{
						formContent.map(sec => (
							(sec.type == 'multiple')
							?
							<Multi 
								key={sec.key}
								u_key={sec.key} 
								label={sec.title}
								options={Object.values(sec.content)} 
								handleChange={handleMultipleChange}
								req={sec.required}
							/>
							:
							(sec.type == 'select')
							?
							<div key={sec.key}>
							<Select 
								
								u_key={sec.key}
								label={sec.title}
								options={Object.values(sec.content)}
								cls='uk-form-width-medium'
								handleChange={handleSelectChange} 
								req={sec.required}
							/>
		
							</div>
							:
							(sec.type == 'title')
							?
							<div key={sec.key} className='uk-margin'>
								<div className='form-section-title'>{sec.title}</div>
								<div className='form-section-content'>{sec.content}</div>
							</div>
							: 
							(sec.type == 'textarea')
							?
							<div key={sec.key}>
								<Textarea 
									u_key={sec.key}
									label={sec.title} 
									handleChange={handleTextChange}
									req={sec.required}
								/>
							</div>
							: ''
						))
						
					}
				</div>
				: ''
			}
			<div className='buttons'>
				
				{
					(!saving)
					? 
					<button className='g-button blue submit-button' onClick={saveEvaluation}>
						Save Evaluation
					</button>
					: 
					<button className='g-button submit-button'  disabled>
						<span>Eval Saving<div className='uk-margin-left' data-uk-spinner='ratio: 0.5'></div></span>
					</button>
				}
				
			</div>
		</div>
	)
}

export default Form;