import React, { useEffect } from 'react';
import './App.scss';

import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from 'firebase-utils/firebase.utils';
import { useDispatch, useSelector } from 'react-redux';

import LandingPage from 'pages/landing-page/landing-page.page'
import LandingContent from 'components/landing-content/landing-content.component'
import HomePage from 'pages/homepage/homepage.page'
import SSO from 'pages/sso/sso.page'
import Logout from 'pages/logout/logout.page'
import Admin from 'pages/admin/admin.page'
import RequireAuth from 'utils/requireAuth'
import AuthWrapper from 'utils/auth-wrapper';
import EvalPage from 'pages/eval-page/eval-page.page';

import { setCurrentUser, setUserProfile, setDbData } from 'state/slices/userSlice';

function App() {

  const currentUser = useSelector(state => state.userData.currentUser)
  const dispatch = useDispatch(); 
  const navigate = useNavigate();

  useEffect(() => {
    // console.log('loading', loading)
    onAuthStateChanged(auth, (user) => {
      console.log('user: ', user)
      console.log('currentUser:', currentUser)
      if (user) {
        const newUser = {
          displayName: user.displayName,
          email: user.email,
          metadata: {
            createdAt: Number(user.metadata.createdAt),
            lastLogInAt: user.metadata.lastLogInAt
          },
          uid: user.uid,
          photoURL: user.photoURL
        }
        dispatch(setCurrentUser({...newUser}));
      } else {
        // let href = window.location.origin
    	// 	// console.log(window.location)
		//     let encoded = encodeURIComponent(href  + `/sso/?token={token}&intendedurl=%2F`)
		//     // console.log(encoded)
		//     window.open(`https://eweb.ilprincipals.org/eweb/?Site=ipa&WebCode=LoginRequired&expires=yes&URL_success=${encoded}`, '_self')
        // navigate('logout')
        // console.log(window.location.pathname)
        if (!window.location.pathname === '/') {
            window.open('https://google.com', '_self')
        }
        dispatch(setCurrentUser(null))
        dispatch(setUserProfile(null))
        dispatch(setDbData(null))
      }
    })
    const links = document.querySelectorAll("[data-part1][data-part2][data-part3]");
		for (const link of links) {
			const attrs = link.dataset;
            link.setAttribute(
                "href",
                `mailto:${attrs.part1}@${attrs.part2}.${attrs.part3}?subject=${attrs.subject}`
            );
            link.textContent = `${attrs.part1}@${attrs.part2}.${attrs.part3}`;
        }
  }, [])

  return (
    <div className="App">
      <Routes>
            <Route path='/*' element={<LandingPage source={'none'} />}></Route>
            <Route path='/:id' element={<LandingContent />} ></Route>
            <Route path='/sso' element={<SSO />}></Route>
            <Route path='/logout' element={<Logout />}></Route>
            {/* <Route element={<AuthWrapper />}> */}
                <Route path='/home/*' element={<HomePage />}></Route>
                <Route path='/eval/*' element={<EvalPage />}></Route>
                <Route path='/admin/*' element={<Admin />}></Route>
            {/* </Route> */}
      </Routes>
    </div>
  );
}

export default App;
