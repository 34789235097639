import React, { useEffect, useState } from 'react'
import './my-evals-list.styles.scss'

import { UseCloudFunction } from 'firebase-utils/firebase.utils'
import { format } from 'date-fns'
import { Sources } from 'forms/formTypes'

import { useSelector } from 'react-redux'

const MyEvalsList = ({ setSource, type }) => {

	const [ evalList, setEvalList ] = useState(null)
	const [ localSource, setLocalSource ] = useState('none')
	const userProfile = useSelector(state => state.userData.userProfile)
	const currentUser = useSelector(state => state.userData.currentUser)
	
	useEffect(() => {
		if (userProfile) {
			getMyEvals();
			setSource('none')
		}
	}, [userProfile])

	useEffect(() => {
		console.log('setting type')
		if (type) {
			setSource(type)
			setLocalSource(type)
		}
	}, [type])
	
	async function getMyEvals() {
		console.log(userProfile.id)
		const res = await UseCloudFunction('getMyEvals', {'cst_key': userProfile.id})
		console.log(res)
		setEvalList(res.evalList)
		
	}
	
	return (
		<div className='g-container my-evals-list'>
			
			{
				(currentUser)
				?
				(evalList)
				?
				<div>
					<div className='section-title'>
						<span className='allcaps'>My Previous Evaluations</span>
					</div>
					<table className="uk-table uk-table-striped">
						<thead>
							<tr>
								<th>Date Completed</th>
								<th>Title</th>
								<th>Type of PD</th>
								<th>Event Date</th>
								<th>Evidence</th>
							</tr>
						</thead>
						<tbody>
							{
								Object.values(evalList)
								.sort((a,b) => (a.ts > b.ts) ? 1 : -1)
								.map(ev => (
									(localSource === Sources[ev.source].cat)
									?
									<tr key={ev.ts}>
										<td>{format(new Date(ev.ts), "Pp")}</td>
										<td>{ev.title}</td>
										<td>{Sources[ev.source].title}</td>
										<td>{(ev.eventDate) ? format(new Date(ev.eventDate), "P") : ''}</td>
										<td>{ev.cpduUrl ? <a href={ev.cpduUrl} target="_blank">Link</a>: ''}</td>
									</tr>
									: null
								))
							}
						</tbody>
					</table>
				</div>
				: 
				<h4>No evaluations completed so far...</h4>
				: 
				<div>
					<div className='g-height-40'></div>
					<div className='uk-container'>
						<h3 className='center'>Please make sure you are using the correct link to the evaluation.<br />Click "Login" above to see your completed evaluations</h3>
					</div>
				</div>
			}
		</div>
	)
}

export default MyEvalsList;