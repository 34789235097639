import React from 'react'

import { doc, getDoc } from "firebase/firestore";
import { db } from 'firebase-utils/firebase.utils'

export default async function GetProfileData(cst_key) {
	console.log('getting data for ' + cst_key)
	const docRef = doc(db, "users", cst_key);
	const docSnap = await getDoc(docRef);
	
	if (docSnap.exists()) {
	  	console.log("returning document!");
		return docSnap.data();
	} else {  	
		console.log("no document!");
	  	return {'res': 'no data'};
	}
}