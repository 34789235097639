import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from 'firebase-utils/firebase.utils';

const AuthWrapper = () => {
  const location = useLocation();
  const [ currentUser, setCurrentUser ] = useState('checking');
  const [ authUser, setAuthUser ] = useState(auth.currentUser)

    useEffect(() => {
        console.log(authUser)
        if (authUser) {
            setCurrentUser(authUser)
        } else {
            setCurrentUser('no user')
        }
    }, [authUser])

    useEffect(() => {
        console.log(currentUser)
    }, [currentUser])

    function useAuth() {
        const [currentUser, setCurrentUser] = useState(); // <-- initially undefined
    
        useEffect(() => {
            const unsub = onAuthStateChanged(auth, (user) => {
                console.log(user);
                setCurrentUser(user)
            }); // <-- null or user object
            return unsub;
        }, []);
    
        return { currentUser };
    }

    if (currentUser === undefined) return null; // <-- or loading spinner, etc...

    if (currentUser === 'no user') return null;
    
    if (currentUser) return <Outlet />
    
    // <Navigate to="/" replace state={{ from: location }} />;
};

export default AuthWrapper;