import React, { useState } from 'react'
import './footer.styles.scss'

import { Link } from 'react-router-dom'

const Footer = ({ source }) => {

	const [ year ] = useState(new Date().getFullYear());

	return (
		<footer>
            {
            (source === 'ipa' || !source) &&
            <div className='footer'>
                <div className='g-grid'>
                    <div className='col1 footer_links_col'>
                        <b className='allcaps'>ILLINOIS PRINCIPALS ASSOCIATION</b>
                        <span>2940 Baker Drive</span><br />
                        <span>Springfield, IL 62703</span>
                        <div style={{'height': '0.75em'}}></div>
                        <span><b>(217) 525-1383</b> (Main Phone)</span>
                        <span>(217) 241-2648 (Membership)</span>
                        <span>(217) 391-0848 (Prof. Dev.)</span>
                        <span>(217) 525-7264 (Fax)</span>
                    </div>
                
                    <div className='col2 footer_links_col'>
                        <span><b className='allcaps'>Office Hours</b></span>
                        <span>8-4 p.m. Monday-Friday</span>
                        <Link to="" data-part1="support" data-part2="ilprincipals" data-part3="org" data-subject="General%20Questions"></Link>
                        <div style={{'height': '1.25em', 'clear': 'both'}}></div>
                        <b className='allcaps'>National Affiliates</b>
                        <Link to='https://www.naesp.org/'>National Association of Elementary School Principals</Link>
                        <Link to='https://www.nassp.org/'>National Association of Secondary School Principals</Link>
                    </div>
                
                    <div className='col3 footer_links_col'>
                        <b className='allcaps'>Quick Links</b>
                        <Link to='https://login.microsoftonline.com/'>Staff email</Link>
                        <Link to='https://connect.ilprincipals.org/organization/ipa-staff/dashboard'>Staff Communications</Link>
                        <Link to='https://connect.ilprincipals.org/organization/board-of-directors/dashboard'>Board of Directors</Link>
                        <Link to='https://connect.ilprincipals.org/organization/region-leaders-committee/dashboard'>Region Leaders</Link>
                        <Link to='https://connect.ilprincipals.org/organization/diversity-equity-committee/dashboard'>Diversity & Equity Committee</Link>
                        <Link to='https://connect.ilprincipals.org/organization/legislative-committee/dashboard'>Legislative Committee</Link>
                        <Link to='https://connect.ilprincipals.org/organization/state-superintendent-principal-advisory-council/dashboard'>State Superintendent Principal Advisory</Link>
                    </div>
                
                    <div className='col4' style={{'maxWidth': '120px'}}>
                        <Link to='https://www.greatplacetowork.com/certified-company/7022473'>
                            <img src='https://ilprincipals.org/images/main/GreatPlaceToWork2022-2023.png' width='120' style={{'display': 'block'}}/>
                        </Link>
                    </div>
                
                </div>
                
                <div className='footer_copyright'>
                    <img src='https://eweb.ilprincipals.org/eweb/images/img_transparent.gif' width='1' height='1' />
                    Copyright &copy; {year} Illinois Principals Association. All rights reserved.
                    <div style={{'height': '0.4em'}}></div>
                    <Link to='https://ilprincipals.org/privacy-policy/'>
                        Privacy&nbsp;Policy
                    </Link> &nbsp;
                    <span style={{'opacity': '0.5'}}>|</span>&nbsp; 
                    <Link to='https://ilprincipals.org/code-of-conduct/'>Code&nbsp;of&nbsp;Conduct</Link>
                    &nbsp;
                    <span style={{'opacity': '0.5'}}>|</span>&nbsp; 
                    <Link to='https://ilprincipals.org/disclaimer/'>Disclaimer</Link>
                </div>
            </div>            
            }
            {
            (source === 'eln') &&
            <div>ELN FOOTER</div>
            }
		</footer>
	)
}

export default Footer;