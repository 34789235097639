export function setSelectedTabs(e) {
	const tabs = document.getElementsByClassName('tab_div');
	for (let t of tabs) {
		t.classList.remove('selected')
		t.classList.add('not_selected')
		if (t.id == e) {
			t.classList.remove('not_selected')
			t.classList.add('selected')
		}
	}
	
}