import React, { useEffect, useState } from 'react';
import './create-eval.styles.scss'

import CreateEvalForm from './create-eval-form.component'

import { setSelectedTabs } from 'utils/tabs'

const NewEval = () => {

	useEffect(() => {
		setTimeout(() => {
            setSelectedTabs('createEvals')
        }, 10)
	}, [])

	return (
		<div className='create-eval'>
			<div className='g-card'>
				<h3>Create a New Evaluation</h3><hr />
				<CreateEvalForm />
			</div>
		</div>
	)
}

export default NewEval;