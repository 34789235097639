import React, { useState, useEffect } from 'react'
import './modal.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const Modal = ({ handleClose, cancel, show, children, text, closeButton, cls, cls2 }) => {
	const showHideClassName = show ? "modal display-block" : "modal display-none";
	
	useEffect(() => {
		console.log(closeButton)
	},[])

	useEffect(() => {
		if (show) {
		    document.body.style.overflow = 'hidden'; 
		} else {
			document.body.style.overflow = 'unset';
		}
	},[show]) 
  	
	return (
    	<div className={showHideClassName}>
      		
			<section className={`modal-main ${cls}`}>
				{
					(closeButton)	
						? <div className='close-button-div'>
							<FontAwesomeIcon className='close-button' icon={faXmark} size='lg' onClick={cancel} />
						</div>
						: ''
				}
        		<div id='modal-children'>
					{children}
				</div>
				<div className='spinner hide'>
					<div className="uk-overlay-default uk-position-cover"></div>
		            <div className="uk-overlay uk-position-center uk-dark">
		            	  <div data-uk-spinner="ratio: 3"></div>
		            </div>
				</div>
      		</section>
    	</div>
  	);
}

export default Modal