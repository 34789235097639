import React from 'react'
import './header-main.styles.scss'

import HeaderTabs from './header-tabs.component'
import { useLocation } from 'react-router-dom'
import eln_logo from 'assets/elnlogo_wht.png';

const HeaderMain = ({ tabs, source }) => {

	const location = useLocation();

	return (
		<div id='header_main_2022' className={`unselectable ${(location.pathname.includes('/admin') || (source === 'eln' || source === 'ipa') && !location.pathname.includes('/eval/')) ? '' : 'no_source'}`}>
			<div id='header_background'></div>
			
			{
				(source || !source) 
				?
				<div id='header_main_inner'>
					<div id='header_main_left'>
						<div id='header_logo_and_title'>

							{
								(source && source === 'ipa') 
								?
								<a href='/'>
									<img className='head-logo' src={require('assets/logo-wht.png')} alt='Illinois Principals Association' title='Illinois Principals Association' />
								</a>
								:
								(source && source === 'eln')
								?
								<a href='/'>
									<img className='head-logo' src={eln_logo} alt='EdLeaders Network' title='EdLeaders Network' />
								</a>
								: 
								<a href='/'>
									<img className='head-logo' src={require('assets/both_logos.png')} alt='IPA and ELN' title='IPA and ELN' />
								</a>
							}
							<a href='/'>
								{/* : 'https://ilprincipals.org/images/headers/logos/20221101-1667333969-656.png' */}
								
							</a>
							<h1 style={{'flex': '1', 'margin': '20px 0px'}} className='allcaps'>
								{
									(source && source === 'ipa') 
									? 'Illinois Principals Association Evaluation'
									: 
									(source && source === 'eln')
									? 'EdLeaders Network Evaluation'
									: 
									(window.location.href.includes('/admin'))
									? 'IPA/ELN Eval Admin'
									: 'IPA/ELN Evaluation Portal'
								}
							</h1>
						</div>
						</div>
						<div id='header_main_right'>
							
						</div>
					</div>
				: null
			}
					
				
			<div className='max_desktop_width'>
				<HeaderTabs tabs={tabs}	/>
			</div>
	    </div>
	)
}

export default HeaderMain;