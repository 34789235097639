import React, { useEffect, useState, version } from 'react'
import './manage-evals.styles.scss'

import { setSelectedTabs } from 'utils/tabs'

import { format } from 'date-fns'
import NewEvalModal from 'components/ADMIN/new-eval-modal/new-eval-modal.component'

import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faLink, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { UseCloudFunction } from 'firebase-utils/firebase.utils'

const ManageEvals = () => {

	const evalList = useSelector(state => state.listsData.evalList)
	const [ newEval, setNewEval ] = useState(false)
	
	useEffect(() => {
		setTimeout(() => {
            setSelectedTabs('manageEvals')
        }, 10)
	}, [])

	useEffect(() => {
		console.log(evalList)
	}, [evalList])

	function changeSource(e) {
		
	}

    // async function updateEvals() {
    //     const res = await UseCloudFunction('updateEvalList', {'data': 'some data'})
    //     console.log(res);
    // }
	
	return (
		<div className='manage-evals'>
			<div className='section-title'>
				<span className='allcaps'>Manage Evaluations</span>
			</div>
			<div className='g-height-40'></div>
			<div className='section-head'>
				<h3>Evaluation List</h3>
                <div>
                    {/* <button className='g-button button-small' onClick={() => updateEvals()}>
                        <FontAwesomeIcon icon={faRefresh} />
                    </button> */}
                    <button className='g-button button-small' onClick={() => setNewEval(true)}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>    
			</div>
			<hr />
			<table className='uk-table uk-striped-table'>
				<thead>
					<tr>
						<th>ID</th>
						<th>Created</th>
						<th>Title</th>
                        <th>Org</th>
						<th>Type</th>
						<th>CPDU</th>
						<th>Hours</th>
						<th>Link</th>
					</tr>
				</thead>
				<tbody>
					{
						(evalList)
						?
						Object.values(evalList)
						.sort((a,b) => (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : -1)
						.map(ev => (
							<tr key={ev.id}>
								<td>{ev.id}</td>
								<td>{format(new Date(ev.ts), "L/d/y")}</td>
								<td>{ev.title}</td>
                                <td>{ev.type}</td>
                                <td>{ev.source}</td>
								<td>{(ev.cpdu) ? 'Yes' : 'No'}</td>
								<td>{ev.hours}</td>
								<td>
									<a className='uk-link-reset' href={`/${ev.id}`} target='_blank'>
										<FontAwesomeIcon icon={faLink} />
									</a>
								</td>
							</tr>
						))
						: <tr><td></td></tr>
					}
				</tbody>
			</table>
			
			
			<NewEvalModal show={newEval} close={() => setNewEval(false)} />
		</div>
	)
}

export default ManageEvals