import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getDatabase } from 'firebase/database'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";

const config = {
	apiKey: "AIzaSyCDfYkQARmeBGRhjDc8H5XoT58btnB2UKs",
 	authDomain: "ipa-evaluation.firebaseapp.com",
	projectId: "ipa-evaluation",
	storageBucket: "ipa-evaluation.appspot.com",
	messagingSenderId: "287635397497",
	appId: "1:287635397497:web:bd9d9218d3cadd6c3f6da8",
	measurementId: "G-7ELHCH8KRT"
};


const app = initializeApp(config);

/**
 * 
 * AUTHENTICATION
 * 
 */


export const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const SignInWithGoogle = () => {
	console.log('signing in')
	signInWithPopup(auth, provider)
	.then((result) => {

	}).catch(err => console.log(err))
}

/**
 * 
 * CREATE USER DOC
 * 
 */

export const db = getFirestore(app);

export const rtDb = getDatabase();

export const createUserProfileDocument = async (userAuth, displayName) => {
	// console.log(userAuth)
	if (!userAuth) return;

	const docRef = doc(db, "users", userAuth.uid)
	const docSnap = await getDoc(docRef)
	let userRef
	if (docSnap.exists()) { 
		userRef = docSnap.data()
		// console.log(docSnap.data())
	} else {
		// console.log(userAuth.uid)
		userRef = {
			name: displayName,
			email: userAuth.email,
			uid: userAuth.uid,
			ts: new Date().getTime()
		}
		await setDoc(doc(db, "users", userAuth.uid), userRef)
	}

	return userRef;
}

/**
 * 
 * SIGN OUT
 * 
 */

const signOut = async () => {
	console.log('signing out')
	await auth.signOut();
	return {'message': 'signed out'}
}

export { signOut }

/**
 *
 * Use Cloud Function
 *
 */

export const UseCloudFunction = async (command, data) => {
	const functions = getFunctions();
	const processData = httpsCallable(functions, 'processCommand');
	const result = await processData({ 
		command: command,
		data: data
	})
	const resData = result.data;
	return resData;
}